import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const ContactForm = () => {
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    message: ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Nombre es requerido'),
    email: Yup.string().email('Email invalido').required('Email es requerido'),
    phone: Yup.string().required('Telefono es requerido'),
    message: Yup.string().required('Mensaje es requerido')
  });

  const onSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      const response = await axios.post('contacto.php', values);
      if (response.data.status === 'succes') {
        setStatus({ success: true });
      } else {
        setStatus({ error: 'Lo sentimos: ocurrio un error al enviar intente mas tarde o conbtactenos por whatsaap.' });
      }
    } catch (error) {
      setStatus({ error: 'Lo sentimos: ocurrio un error al enviar intente mas tarde o conbtactenos por whatsaap.' });
    }
    setSubmitting(false);
  };

  return (
    <div className="bg-blue-100 p-6 rounded-lg shadow-lg">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, status }) => (
          <Form>
            {status && status.success && <div className="text-green-500">Mensaje recibido correctamente</div>}
            {status && status.error && <div className="text-red-500">{status.error}</div>}
            {!status?.success && (
              <>
                <div className="mb-4">
                  <label className="block text-blue-900 text-sm font-bold mb-2" htmlFor="name">Nombre</label>
                  <Field
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-blue-900 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Tu nombre"
                  />
                  <ErrorMessage name="name" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="mb-4">
                  <label className="block text-blue-900 text-sm font-bold mb-2" htmlFor="email">Email</label>
                  <Field
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-blue-900 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Tu email"
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="mb-4">
                  <label className="block text-blue-900 text-sm font-bold mb-2" htmlFor="phone">Telefono</label>
                  <Field
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-blue-900 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="Tu Telefono"
                  />
                  <ErrorMessage name="phone" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="mb-4">
                  <label className="block text-blue-900 text-sm font-bold mb-2" htmlFor="message">Mensaje</label>
                  <Field
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-blue-900 leading-tight focus:outline-none focus:shadow-outline"
                    id="message"
                    name="message"
                    as="textarea"
                    rows="5"
                    placeholder="Tu mensaje"
                  />
                  <ErrorMessage name="message" component="div" className="text-red-500 text-xs mt-1" />
                </div>
                <div className="flex items-center justify-between">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Enviando...' : 'Enviar'}
                  </button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
