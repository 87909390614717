import React, { useState } from 'react';

const photos = [
  '/images/galery/1.png',
  '/images/galery/2.png',
  '/images/galery/3.png',
  '/images/galery/4.png',
  '/images/galery/5.png',
  '/images/galery/6.png',
  '/images/galery/7.png',
  '/images/galery/8.png',
  '/images/galery/9.png',
  '/images/galery/10.png',
  '/images/galery/11.png',
  '/images/galery/12.png',
  '/images/galery/13.png',
];

const PhotoGallery = () => {
  const [lightboxImage, setLightboxImage] = useState(null);

  const openLightbox = (photo) => {
    setLightboxImage(photo);
  };

  const closeLightbox = () => {
    setLightboxImage(null);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {photos.map((photo, index) => (
          <div key={index} className="relative group">
            <img
              src={photo}
              alt={`Photo ${index + 1}`}
              className="w-full h-auto object-cover rounded-lg shadow-lg cursor-pointer"
              onClick={() => openLightbox(photo)}
            />
          </div>
        ))}
      </div>

      {lightboxImage && (
        <div
          className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 z-50"
          onClick={closeLightbox}
        >
          <img
            src={lightboxImage}
            alt="Lightbox"
            className="max-w-full max-h-full"
          />
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
