import logo from './logo.svg';
import LegalHeader from "./components/LegalHeader.js";
import ContactForm from "./components/ContactForm.js";
import { FaPhone, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { MdMoneyOff } from "react-icons/md";
import { GiOpenBook } from "react-icons/gi";
import { FaTrophy } from "react-icons/fa";
import { GiThermometerCold } from "react-icons/gi";

import { Accordion, Divider } from "keep-react";

import autoPlay from 'embla-carousel-autoplay';
import { Carousel } from 'keep-react';
import './App.css';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactTooltip from 'react-tooltip';

import PhotoGallery from "./components/PhotoGallery.js";
import CarouselClientes from "./components/CarouselClientes.js";




function App() {


  return (
    <div>
      <Helmet>
        <title>LiveCold - Refrigeración - Aire Acondicionado - Calefaccción</title>
        <meta name="description" content="LiveCold - Expertos en Refrigeración, Aire Acondicionado y Calefacción en Rancagua. Ofrecemos soluciones eficientes y de calidad para mantener tu hogar o negocio a la temperatura perfecta." />
      </Helmet>
      <div className="flex justify-center">
        <div className='container max-w-1100 mx-auto px-4'>
          <LegalHeader />
        </div>
      </div>

      <div className="flex justify-center bg-blue-900 text-white" style={{backgroundImage: 'url(/images/bgs12.png)', backgroundSize: 'cover'}}>
        <div className='container max-w-1100 mx-auto px-4 pb-5'>
          <div className="relative">
            <img src="/images/abogados.png" id='fotoPrincipal' className="w-full my-10 rounded-lg hidden sd:block  md:block lg:block xl:block" alt="Abogados" />
            <img src="/images/abogados_movil.png" id='fotoPrincipal' className="w-full my-10 rounded-lg  block sd:hidden md:hidden lg:hidden xl:hidden" alt="Abogados" />
            <div style={{ boxShadow: '0 4px 6px rgba(0, 0, 255, 0.5)', padding: '16px' }} className="md:absolute md:top-10 md:right-12 bg-white rounded-lg min-w-[320px] w-full md:max-w-[320px] lg:max-w-[320px]  xl:max-w-[320px] p-4 shadow-lg">
              <h1 className="text-xl text-blue-800 font-bold mb-4">Somos Expertos !</h1>
              <ul className="mb-4">
                <li className="flex items-center mb-2  text-blue-800"><GiThermometerCold className="mr-2 text-blue-800" /> Expertos en &nbsp;<span className=" font-semibold">instalación de equipos</span></li>
                <li className="flex items-center mb-2  text-blue-800"><GiThermometerCold className="mr-2 text-blue-800" /> Expertos en&nbsp;<span className=" font-semibold">mantención</span></li>
                <li className="flex items-center mb-2  text-blue-800"><GiThermometerCold className="mr-2 text-blue-800" /> Expertos en&nbsp;<span className=" font-semibold">reparación</span></li>
                <li className="flex items-center mb-2  text-blue-800"><GiThermometerCold className="mr-2 text-blue-800" /> Expertos en &nbsp;<span className=" font-semibold">canalización</span></li>
              </ul>
              <a style={{backgroundColor:'#c49b73', borderColor:'#c49b73'}} className='inline-flex items-center px-4 py-2 border-2  bg-blue-800 text-white text-xl rounded-full transition-transform duration-300 hover:bg-blue-900 hover:scale-105' href='#conversemos'>
                <FaPhone className="mr-2" data-tip="Llamar Ahora" /> Recibir asesoría gratis
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="w-full mx-auto my-3" >
          <div className="flex justify-center">
            <div className="w-full mx-auto" >
              <h1 className='mx-auto text-center text-4xl roboto px-4 py-2  text-blue-900 rounded-full w-1/2 font-bold'>¿POR QUÉ <span className="text-orange-500 font-bold">NOSOTROS?</span></h1>
              <p  className='mx-auto text-center text-2xl roboto px-4 py-2  w-full md:w-3/5 font-bold'>Nuestros especialistas en refrigeración y climatización te respaldan para garantizar tu bienestar y resolver cualquier desafío relacionado con el confort térmico en tu hogar y empresa.</p>
            
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-4 mb-2">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          
        <div className="bg-blue-500 text-white p-6 rounded-lg flex flex-col items-center">
      <FaMoneyBillTrendUp className="text-5xl mb-2" />
      <span className='text-center'>Facilidades de pago</span>
    </div>


    <div className="bg-blue-500 text-white p-6 rounded-lg flex flex-col items-center">
      <MdMoneyOff className="text-5xl mb-2" />
      <span className='text-center'>Proyecto a su Medida.</span>
    </div>

    <div className="bg-blue-500 text-white p-6 rounded-lg flex flex-col items-center">
      <GiOpenBook className="text-5xl mb-2" />
      <span className='text-center'>+15 años de experiencia.</span>
    </div>

    <div className="bg-blue-500 text-white p-6 rounded-lg flex flex-col items-center">
      <FaTrophy className="text-5xl mb-2" />
      <span className='text-center'>+1200 Equipos Instalados.</span>
    </div>


        </div>
      </div>


      <div className="flex justify-center">
        <div className="w-full bg-blue-100 p-5">
          <div className="flex justify-center0">
            <div className="w-full mx-auto">
              
            <div className='container max-w-1100 mx-auto px-4 pb-5'>
              
              <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div class="p-4">
                  <img src="/images/trabajando.png" className='w-full' />

                </div>
                <div class=" p-4">
                      <h1 className='text-5xl text-blue-900 roboto py-2 '><span className='font-bold'>LiveCold</span> Refrigeración Manteción</h1>
                      <br/>
                      <p className='text-xl text-blue-900'>La mantención anual del aire acondicionado es crucial para asegurar su eficiencia y prolongar su vida útil. Permite detectar y corregir problemas menores, mejorar el rendimiento y mantener una calidad de aire interior saludable, previniendo la acumulación de polvo y bacterias.</p>
                      <br/><br/>
                      <a href="#conversemos" className='mt-4 p-5  bg-orange-600 mt-2 font-normal rounded text-white '>Solicitar Servicio</a>
                </div>
              </div>

            </div>

            </div>
          </div>
        </div>
      </div>


      <div className="flex justify-center">
        <div className="w-full bg-white p-5">
          <div className="flex justify-center0">
            <div className="w-full mx-auto">
              
            <div className='container max-w-1100 mx-auto px-4 pb-5'>
              
              
                      <h1 className='text-5xl text-blue-900 roboto py-2 text-center '><span className='font-bold'>Cámaras de Frío</span></h1>
                      <br/>
                       <p className='text-2xl roboto text-blue-900 text-center'>La instalación y mantención de cámaras de frío es crucial para su eficiencia y longevidad. Un mantenimiento regular permite detectar y solucionar problemas antes de que se conviertan en fallas costosas, asegurando un rendimiento óptimo y una conservación adecuada de los productos. Con más de 15 años de experiencia, ofrecemos un servicio de mantención de alta calidad, garantizando que sus cámaras de frío funcionen de manera eficiente y fiable.</p>

            </div>
            <div className='container max-w-1100 mx-auto px-4 pb-5 flex justify-center items-center'>
              <img src="/images/camara.png" className='w-1/2' />
            </div>


            </div>
          </div>
        </div>
      </div>      



      <div className="flex justify-center">
        <div className="w-full bg-blue-100 p-5">
          <div className="flex justify-center0">
            <div className="w-full mx-auto">
              <h1 className='mx-auto text-center text-3xl roboto  px-4 py-2  text-black font-semibold'>¿Necesitas a los mejores?</h1>
              <div className="flex justify-center  flex-col sm:flex-row space-x-4 mt-4 py-4">
                <a className="mb-4 inline-flex items-center px-4 py-2 border-2 border-blue-900 bg-blue-800 text-white rounded-full transition-transform duration-300 hover:bg-blue-900 hover:scale-105" href="mailto:info@abogadoslaborales.cl">
                  <FaEnvelope className="mr-2" /> Enviar email 
                </a>
                <a className="mb-4 inline-flex items-center px-4 py-2 border-2 border-blue-900 bg-blue-800 text-white rounded-full transition-transform duration-300 hover:bg-blue-900 hover:scale-105" href="tel:+56952366372">
                <FaPhone className="mr-2"   /> Que me llamen
                </a>
                <a  target='blank' className="mb-4 inline-flex items-center px-4 py-2 border-2 border-blue-900 bg-blue-800 text-white rounded-full transition-transform duration-300 hover:bg-blue-900 hover:scale-105" href="https://api.whatsapp.com/send/?phone=56952366372&text=Hola,%20Vengo%20desde%20la%20web%20y%20tengo%20la%20siguiente%20consulta&type=phone_number&app_absent=0">
                  <FaWhatsapp className="mr-2" /> Consultar por WhatsApp
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


<div  style={{backgroundImage: 'url(/images/bgs14.png)', backgroundSize: 'cover'}}>
      <div className="container mx-auto p-5" >
        <h2 className="text-center text-2xl mt-2 font-bold leading-3 mb-10 ">Nuestros Clientes </h2>
        <div className="w-full">


        <Carousel options={{ loop: true }} plugins={[autoPlay()]}>
  <Carousel.Slides>
    <Carousel.Item key="1">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="flex min-h-[260px] items-start justify-start rounded-xl border border-metal-100 bg-primary-25 p-6">
          <div>
            <div className="text-lg font-bold">Nelly Astudillo</div>
            <div className="text-yellow-500">★★★★★</div>
            <p className="mt-2">
              “El equipo de refrigeración instalado por ustedes ha funcionado de maravilla, estoy muy contenta con su profesionalismo y dedicación.”
            </p>
          </div>
        </div>
        <div className="hidden md:block flex min-h-[260px] items-start justify-start rounded-xl border border-metal-100 bg-primary-25 p-6">
          <div>
            <div className="text-lg font-bold">Omar Jesús Ramírez Lara</div>
            <div className="text-yellow-500">★★★★★</div>
            <p className="mt-2">
              “Gracias por su excelente servicio de mantenimiento. Mis equipos de aire acondicionado están en perfectas condiciones.”
            </p>
          </div>
        </div>
        <div className="hidden md:block flex min-h-[260px] items-start justify-start rounded-xl border border-metal-100 bg-primary-25 p-6">
          <div>
            <div className="text-lg font-bold">Jorge Andrés Badilla López</div>
            <div className="text-yellow-500">★★★★★</div>
            <p className="mt-2">
              “El servicio técnico de su empresa es de primer nivel. La instalación fue rápida y eficiente.”
            </p>
          </div>
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item key="2">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="flex min-h-[260px] items-start justify-start rounded-xl border border-metal-100 bg-primary-25 p-6">
          <div>
            <div className="text-lg font-bold">Julio Zápata Mora</div>
            <div className="text-yellow-500">★★★★★</div>
            <p className="mt-2">
              “Estoy muy satisfecho con la instalación de mi nuevo aire acondicionado. La calidad del trabajo es excelente.”
            </p>
          </div>
        </div>
        <div className="hidden md:block flex min-h-[260px] items-start justify-start rounded-xl border border-metal-100 bg-primary-25 p-6">
          <div>
            <div className="text-lg font-bold">Cliente Adicional</div>
            <div className="text-yellow-500">★★★★★</div>
            <p className="mt-2">
              “La reparación de mi sistema de refrigeración fue rápida y efectiva. ¡Muy recomendado!”
            </p>
          </div>
        </div>
        <div className="hidden md:block flex min-h-[260px] items-start justify-start rounded-xl border border-metal-100 bg-primary-25 p-6">
          <div>
            <div className="text-lg font-bold">Isabel María López Miranda</div>
            <div className="text-yellow-500">★★★★★</div>
            <p className="mt-2">
              “Excelente atención y servicio. Mi aire acondicionado funciona perfectamente gracias a ustedes.”
            </p>
          </div>
        </div>
      </div>
    </Carousel.Item>
    {/* Agrega más elementos Carousel.Item si es necesario */}
  </Carousel.Slides>
  <Carousel.Control>
    <Carousel.Buttons>
      <Carousel.PrevButton />
      <Carousel.NextButton />
    </Carousel.Buttons>
    <Carousel.Indicators />
  </Carousel.Control>
</Carousel>



        </div>

<br/>
        <Divider />

      </div>
      </div>


<div className='w-full container mx-auto p-4'>
  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 gap-4">
    <div className="p-4 ">
    <h2 style={{backgroundColor:'#c49b73', borderColor:'#c49b73'}} className=" text-center text-2xl font-bold mb-6 text-white rounded-full">No importa la hora &nbsp;<span className='text-xl italic font-semibold mb-6 text-white'>¡Contactanos!</span></h2>
 

    <Carousel options={{ loop: true }} plugins={[autoPlay()]}>
      <Carousel.Slides>
          <Carousel.Item key="1">
            <div className="flex items-center justify-center rounded-xl border border-metal-100 bg-primary-25 p-3">
              <img src="/images/t1.png" />
            </div>
          </Carousel.Item>
          <Carousel.Item key="2">
            <div className="flex items-center justify-center rounded-xl border border-metal-100 bg-primary-25 p-3">
              <img src="/images/t2.png" />
            </div>
          </Carousel.Item>
          <Carousel.Item key="3">
            <div className="flex items-center justify-center rounded-xl border border-metal-100 bg-primary-25 p-3">
              <img src="/images/t3.png" />
            </div>
          </Carousel.Item>          
      </Carousel.Slides>
      <Carousel.Control>
        <Carousel.Buttons>
          <Carousel.PrevButton />
          <Carousel.NextButton />
        </Carousel.Buttons>
        <Carousel.Indicators />
      </Carousel.Control>
    </Carousel>


      
    </div>
    <div className="p-4" >
      <h2 id="conversemos" className="sm:bg-blue-100  text-center text-3xl font-bold mb-6 text-blue-900 rounded-full">Conversemos&nbsp;<span className='text-2xl italic font-semibold mb-6 text-blue-900'>¡Tú consulta es gratuita!</span></h2>
      <ContactForm/>
    </div>
  </div>

</div>

<div className="flex justify-center">
        <div className='container max-w-900 mx-auto px-4 py-10'>
        <Divider />
        </div>
</div>


<div className="flex justify-center  " >
        <div className='container max-w-900 mx-auto px-4 text-center font-bold text-3xl text-dark-blue  text-body-1  '>
          PREGUNTAS FRECUENTES
        </div>
</div>

<div className="flex justify-center"   style={{backgroundImage: 'url(/images/bgs13.png)', backgroundSize: 'cover'}}>
        <div className='container max-w-900 mx-auto px-10 py-10 '>
          



        <Accordion flush={true}>
  <Accordion.Panel>
    <Accordion.Container>
      <Accordion.Title>
        <div className="flex items-center">
          <GiThermometerCold size="20" />
          <span className="ml-2">¿Cómo funciona un aire acondicionado?</span>
        </div>
      </Accordion.Title>
      <Accordion.Icon />
    </Accordion.Container>
    <Accordion.Content>
      Un aire acondicionado funciona mediante el ciclo de refrigeración, extrayendo el calor del interior y liberándolo al exterior, utilizando un compresor, un condensador, un evaporador y un refrigerante.
    </Accordion.Content>
  </Accordion.Panel>
  
  <Accordion.Panel>
    <Accordion.Container>
      <Accordion.Title>
        <div className="flex items-center">
          <GiThermometerCold size="20" />
          <span className="ml-2">¿Qué mantenimiento necesita un sistema de calefacción?</span>
        </div>
      </Accordion.Title>
      <Accordion.Icon />
    </Accordion.Container>
    <Accordion.Content>
      Un sistema de calefacción necesita un mantenimiento regular que incluye la limpieza de los filtros, revisión de los conductos, inspección del termostato, y chequeo del sistema de ventilación para asegurar su eficiencia y seguridad.
    </Accordion.Content>
  </Accordion.Panel>
  
  <Accordion.Panel>
    <Accordion.Container>
      <Accordion.Title>
        <div className="flex items-center">
          <GiThermometerCold size="20" />
          <span className="ml-2">¿Cómo se realiza la instalación de una cámara de frío?</span>
        </div>
      </Accordion.Title>
      <Accordion.Icon />
    </Accordion.Container>
    <Accordion.Content>
      La instalación de una cámara de frío requiere de una correcta planificación del espacio, montaje de paneles aislantes, instalación de puertas, y montaje del sistema de refrigeración que incluye el compresor, el evaporador y el sistema de control de temperatura.
    </Accordion.Content>
  </Accordion.Panel>
  
  <Accordion.Panel>
    <Accordion.Container>
      <Accordion.Title>
        <div className="flex items-center">
          <GiThermometerCold size="20" />
          <span className="ml-2">¿Cuáles son los beneficios de un aire acondicionado inverter?</span>
        </div>
      </Accordion.Title>
      <Accordion.Icon />
    </Accordion.Container>
    <Accordion.Content>
      Los aires acondicionados inverter son más eficientes energéticamente, proporcionan una temperatura más constante, tienen una vida útil más larga y funcionan de manera más silenciosa en comparación con los modelos tradicionales.
    </Accordion.Content>
  </Accordion.Panel>
  
  <Accordion.Panel>
    <Accordion.Container>
      <Accordion.Title>
        <div className="flex items-center">
          <GiThermometerCold size="20" />
          <span className="ml-2">¿Qué tipos de sistemas de calefacción existen?</span>
        </div>
      </Accordion.Title>
      <Accordion.Icon />
    </Accordion.Container>
    <Accordion.Content>
      Existen varios tipos de sistemas de calefacción, incluyendo la calefacción por radiadores, calefacción por suelo radiante, bombas de calor, calefacción eléctrica y sistemas de calefacción centralizada.
    </Accordion.Content>
  </Accordion.Panel>
  
  <Accordion.Panel>
    <Accordion.Container>
      <Accordion.Title>
        <div className="flex items-center">
          <GiThermometerCold size="20" />
          <span className="ml-2">¿Cómo elegir el aire acondicionado adecuado para mi hogar?</span>
        </div>
      </Accordion.Title>
      <Accordion.Icon />
    </Accordion.Container>
    <Accordion.Content>
      Para elegir el aire acondicionado adecuado, considera el tamaño de la habitación, la eficiencia energética del equipo, el tipo de unidad (split, ventana, portátil), el nivel de ruido, y las características adicionales como el control remoto o la conectividad wifi.
    </Accordion.Content>
  </Accordion.Panel>
  
  <Accordion.Panel>
    <Accordion.Container>
      <Accordion.Title>
        <div className="flex items-center">
          <GiThermometerCold size="20" />
          <span className="ml-2">¿Qué es el mantenimiento preventivo en cámaras de frío?</span>
        </div>
      </Accordion.Title>
      <Accordion.Icon />
    </Accordion.Container>
    <Accordion.Content>
      El mantenimiento preventivo en cámaras de frío incluye la revisión y limpieza de los componentes del sistema de refrigeración, chequeo de las temperaturas y presiones de operación, inspección de los aislamientos y sellos, y verificación del estado de los motores y compresores.
    </Accordion.Content>
  </Accordion.Panel>
</Accordion>



        </div>
</div>


<div className="flex justify-center  " >
        <div className='container max-w-900 mx-auto px-4 text-center font-bold text-3xl text-dark-blue  text-body-1  '>
         ALGUNOS DE NUESTROS CLIENTES
        </div>
</div>

<div className="flex justify-center  " >
        <div style={{maxWidth:'400px'}} className='container max-w-400 max-h-400 mx-auto px-4 text-center font-bold text-3xl text-dark-blue  text-body-1  '>
          <CarouselClientes/>
        </div>
</div>


<div className="flex justify-center  " >
        <div className='container max-w-900 mx-auto px-4 text-center font-bold text-3xl text-dark-blue  text-body-1  '>
          GALERIA DE FOTOS ALGUNOS TRABAJOS REALIZADOS
        </div>
</div>

<div className="flex justify-center  " >
        <div className='container max-w-900 mx-auto px-4 text-center font-bold text-3xl text-dark-blue  text-body-1  '>
          <PhotoGallery/>
        </div>
</div>

 
      <br/>
      <br/>      
      <br/>    
      <br/>      
      <br/>
 
   
      <div className='fixed bottom-0 w-full h-30 md:h-24 bg-blue-900 text-white pt-1'>
          <div className="flex justify-center">
            <div className='container max-w-900 mx-auto px-4'>

              <div class="grid grid-cols-2 gap-4">
                <div class="p-3">
                  <h1 className='text-center text-xl md:text-2xl mt-2 font-bold leading-4 md:leading-3'>¿Necesitas refrigeración o calefacción?</h1>
                  <h1 className='text-center  text-normal md:text-xl font-semibold leading-4 mt-2'>Asesoria gratis.</h1>
                </div>
                <div class="flex justify-start "><a href="#conversemos" className="p-5 my-3 bg-orange-600 mt-2 font-bold ">Haz tu Consulta ahora!</a> </div>
              </div>

            </div>
          </div>
      </div>

      <div className="fixed right-4 top-1/2 transform -translate-y-1/2 flex flex-col items-center space-y-4">
      <div className="bg-green-500 text-white p-3 rounded-full shadow-lg">
        <a target='blank' href="https://api.whatsapp.com/send/?phone=56952366372&text=Hola,%20Vengo%20desde%20la%20web%20y%20tengo%20la%20siguiente%20consulta&type=phone_number&app_absent=0"><FaWhatsapp size="24" /></a>
      </div>
      <div className="bg-blue-500 text-white p-3 rounded-full shadow-lg">
        <a href="tel:+56952366372"  data-tip="Llamar Ahora" ><FaPhone size="24"  /></a>
      </div>
    </div>




    </div>
  );
}

export default App;
