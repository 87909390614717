import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Estilos prediseñados del carrusel

const CarouselClientes = () => {
  const images = Array.from({ length: 30 }, (_, index) => ({
    id: index + 1,
    url: `/images/clientes/${index + 1}.png`,
    alt: `Cliente ${index + 1}`
  }));

  return (
    <div className="max-w-screen-lg mx-auto p-4">
      <Carousel
        showArrows={true}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000} // Cambia cada 3 segundos
        stopOnHover={true}
        showStatus={false}
      >
        {images.map((image) => (
          <div key={image.id}>
            <img src={image.url} alt={image.alt} className="object-cover rounded-lg max-h-400" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselClientes;
