import { FaPhone } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";

function LegalHeader() {
  return (
    <header className="w-full px-1 py-7" >
      <nav className="bg-white border-gray-200 py-2.5 ">
        <div className="flex flex-wrap items-start justify-between mx-auto">
          <a href="https://www.livecold.cl" className="flex items-center">
            <img
              src="./images/logo.png"
              className="h-10 mr-3 sm:h-14"
              alt=" Logo"
            />
          </a>
          <div className="flex items-center lg:order-2">
          <a href="tel:56952366372" className="inline-flex items-center px-4 py-2 border-2 border-blue-900 text-blue-900 text-xl  rounded-full transition-transform duration-300 hover:bg-blue-900 hover:text-white hover:scale-105">
    <FaPhone className="mr-2" /> <span className="hidden sd:block md:block lg:block xl:block">Llamar</span></a>


  <a 
    href="https://api.whatsapp.com/send/?phone=56952366372&text=Hola,%20Vengo%20desde%20la%20web%20y%20tengo%20la%20siguiente%20consulta&type=phone_number&app_absent=0" 
    className="ml-2 inline-flex items-center px-4 py-2 border-2 border-green-600 bg-green-600 text-white text-xl  rounded-full transition-transform duration-300 hover:bg-green-900 hover:text-white hover:scale-105"
  >
    <IoLogoWhatsapp className="mr-2" /> <span className="hidden sd:block md:block lg:block xl:block">WhatsApp</span>  </a>
            
            <button
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="hidden inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded="false"
            >
              <span className="sr-only">Abrir Menú</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                 ></path>
              </svg>
              <svg
                className="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                   
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                ></path>
              </svg>
            </button>
          </div>
          <div
            className="mt-2 items-center justify-between  items-start  hidden w-full lg:flex lg:w-auto lg:order-1"
            id="mobile-menu-2"
          >
            <ul className="flex flex-col font-normal lg:flex-row lg:space-x-14 lg:mt-0">
              <li>
                <a
                  href="#programacion"
                  className="relative text-xl block py-2 pl-3 pr-4 text-black border-b border-gray-100 lg:border-0 lg:p-0"
                >
                  <span className="absolute inset-0 opacity-0 hover:opacity-100">
                    Camaras de Frío
                  </span>
                  <span className="opacity-100">Camaras de Frío</span>
                </a>
              </li>
              <li>
                <a
                  href="#tickets"
                  className="relative text-xl block py-2 pl-3 pr-4 text-black border-b border-gray-100 lg:border-0 lg:p-0"
                >
                  <span className="absolute inset-0 opacity-0 hover:opacity-100">
                    Calefacción
                  </span>
                  <span className="opacity-100">Calefacción</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default LegalHeader;
